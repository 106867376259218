import React from "react";
import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DEVICE_CATEGORY, USER, MODULES as M } from "../../config/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

const SubMenu = Menu.SubMenu;

const getFullMenu = (me) => {
   return [
      { title: "dashboard", route: "/admin", icon: duotone("grid-horizontal") },
      { title: "-" },
      {
         title: "statistics",
         route: "/admin/statistics",
         icon: duotone("list-ul"),
         modules: [M.WORKWEAR, M.DPI, M.CONSUMABLES],
      },
      {
         title: "warehouse",
         route: "/admin/warehouse/warehouse",
         icon: duotone("boxes-stacked"),
         modules: [M.WORKWEAR, M.DPI, M.FIRSTAIDKIT, M.CONSUMABLES],
      },
      {
         title: "reservations",
         route: "/admin/warehouse/reservations",
         icon: duotone("clipboard-list"),
         modules: me.workspace.lite_version ? [] : [M.WORKWEAR, M.CONSUMABLES],
      },
      {
         title: "orders",
         route: "/admin/warehouse/orders",
         icon: duotone("clipboard-list"),
         modules: [M.DPI],
      },
      {
         title: "inventory_returns",
         route: "/admin/warehouse/inventory-returns",
         icon: duotone("dolly"),
         modules: me.workspace.lite_version ? [] : [M.WORKWEAR, M.DPI],
      },
      {
         title: "reports",
         route: "/admin/reports",
         icon: duotone("bell"),
         modules: me.workspace.lite_version ? [] : [M.WORKWEAR],
      },
      { title: "-" },
      {
         title: "employees",
         route: "/admin/employees/employees",
         icon: duotone("people-group"),
         modules: [M.WORKWEAR, M.DPI, M.CONSUMABLES, M.KEYS],
      },
      {
         title: "training_documents",
         route: "/admin/employees/training-documents",
         icon: duotone("file"),
         modules: [M.DPI],
      },
      {
         title: "products",
         route: "/admin/catalog/products",
         icon: duotone("box"),
         modules: [M.WORKWEAR, M.DPI, M.FIRSTAIDKIT, M.CONSUMABLES],
      },
      {
         title: "kits",
         route: "/admin/catalog/kits",
         icon: duotone("box-open-full"),
         modules: [M.WORKWEAR, M.DPI, M.CONSUMABLES],
      },
      {
         title: "vehicles",
         route: "/admin/catalog/vehicles",
         icon: duotone("car"),
         modules: [],
      },
      {
         title: "lock_keys",
         route: "/admin/catalog/lock-keys",
         icon: duotone("key"),
         modules: [M.KEYS],
      },
      { title: "-" },
      {
         title: "service_distributors",
         route: `/admin/devices/${DEVICE_CATEGORY.DISTRIBUTOR}`,
         icon: duotone("distribute-spacing-horizontal"),
         modules: me.workspace.lite_version ? [] : [M.DPI, M.CONSUMABLES],
      },
      {
         title: "reservations",
         route: "/admin/scheduled-reservations",
         icon: duotone("clipboard-list"),
         modules: [M.KEYS],
      },
      {
         title: "service_lock_key_distributors",
         route: `/admin/devices/${DEVICE_CATEGORY.LOCK_KEY_DISTRIBUTOR}`,
         icon: duotone("key"),
         modules: me.workspace.lite_version ? [] : [M.KEYS],
      },
      {
         title: "service_lockers",
         route: `/admin/devices/${DEVICE_CATEGORY.LOCKER}`,
         icon: duotone("table-cells"),
         modules: me.workspace.lite_version ? [] : [M.WORKWEAR, M.DPI, M.CONSUMABLES],
      },
      {
         title: "service_trackers",
         route: `/admin/devices/${DEVICE_CATEGORY.TRACKER}`,
         icon: duotone("location-dot"),
         modules: me.workspace.lite_version ? [] : [M.KEYS],
      },
      {
         title: "service_faks",
         route: `/admin/devices/${DEVICE_CATEGORY.MEDICAL_BOX}`,
         icon: duotone("suitcase-medical"),
         modules: me.workspace.lite_version ? [] : [M.FIRSTAIDKIT],
      },
      { title: "-", modules: me.workspace.lite_version ? [] : null },

      {
         title: "settings",
         icon: duotone("circle"),

         submenu: [
            {
               title: "products_categories",
               route: "/admin/catalog/categories",
               icon: duotone("circle"),
               modules: [M.WORKWEAR, M.DPI, M.CONSUMABLES],
            },
            {
               title: "lock_keys_categories",
               route: "/admin/catalog/lock-keys-categories",
               icon: duotone("circle"),
               modules: [M.KEYS],
            },
            {
               title: "products_groups",
               route: "/admin/catalog/products-groups",
               icon: duotone("circle"),
               modules: [M.WORKWEAR, M.DPI, M.CONSUMABLES],
            },
            {
               title: "products_attributes",
               route: "/admin/catalog/attributes",
               icon: duotone("circle"),
               modules: [M.WORKWEAR, M.DPI, M.CONSUMABLES],
            },
            {
               title: "employees_categories",
               route: "/admin/employees/categories",
               icon: duotone("circle"),
               modules: [M.WORKWEAR, M.DPI, M.CONSUMABLES, M.KEYS],
            },
            {
               title: "employees_departments",
               route: "/admin/employees/departments",
               icon: duotone("circle"),
               modules: [M.WORKWEAR, M.DPI, M.CONSUMABLES, M.KEYS],
            },
            {
               title: "notifications",
               route: "/admin/notifications",
               icon: duotone("circle"),
            },
            {
               title: "users",
               route: "/admin/users",
               icon: duotone("circle"),
            },
         ],
      },
      { title: "-" },
      {
         title: "diagnostic",
         route: "/admin/diagnostic",
         icon: duotone("user-nurse-hair-long"),
         modules: [M.WORKWEAR, M.DPI, M.CONSUMABLES, M.KEYS],
      },
   ];
};
const fastMenu = [
   {
      title: "manual_consign",
      route: "/admin/delivery/manual",
      icon: duotone("person-carry-box"),
      modules: [M.WORKWEAR, M.DPI, M.CONSUMABLES],
   },
];

const employeeMenu = [
   { title: "dashboard", route: "/admin", icon: duotone("grid-horizontal") },
   {
      title: "reservations",
      route: "/admin/scheduled-reservations",
      icon: duotone("clipboard-list"),
      modules: [M.KEYS],
   },
]

const adminMenu = [
   {
      title: "admin",
      icon: duotone("lock"),
      submenu: [
         {
            title: "dashboard",
            route: "/admin/workspaces-dashboard",
            icon: duotone("lock"),
         },
         {
            title: "users_management",
            route: "/admin/user-manager",
            icon: duotone("lock"),
         },
         {
            title: "partners",
            route: "/admin/partners",
            icon: duotone("lock"),
         },
         {
            title: "workspaces",
            route: "/admin/workspaces",
            icon: duotone("lock"),
         },
         { title: "-" },
         {
            title: "products",
            route: "/admin/catalog/products/master",
            icon: duotone("box"),
            modules: [M.WORKWEAR, M.DPI, M.FIRSTAIDKIT],
         },
         {
            title: "warehouse",
            route: "/admin/warehouse/warehouse/master",
            icon: duotone("boxes-stacked"),
            modules: [M.WORKWEAR, M.DPI, M.FIRSTAIDKIT],
         },
         {
            title: "surveys",
            route: "/admin/surveys",
            icon: duotone("file"),
            modules: [M.KEYS],
         },
         {
            title: "service_distributors",
            route: `/admin/devices/${DEVICE_CATEGORY.DISTRIBUTOR}`,
            icon: duotone("distribute-spacing-horizontal"),
            modules: [M.KEYS],
         },
      ],
   },
   {
      title: "demostration",
      icon: duotone("desktop"),
      submenu: [
         {
            title: "KT First Aid Kit",
            route: "/admin/demo/fak",
            icon: duotone("display-medical"),
         },
         {
            title: "KeepTrack App",
            route: "/admin/demo/app",
            icon: duotone("display-code"),
         },
         {
            title: "KeepTrack Totem",
            route: "/admin/demo/live",
            icon: duotone("display"),
         },
      ],
   },
];




const MainMenu = ({ me }) => {
   const { t } = useTranslation();

   const showMenu = (menu, prefix) => {
      return (
         menu
            //filtra i menu non abilitati
            .filter((i) => {
               if (i.modules && !i.modules.includes(me.module.slug)) {
                  return false;
               }

               return true;
            })
            //filtra i separatori doppi
            .filter((i, index) => {
               if (menu[index - 1] && menu[index - 1].title === "-" && i.title === "-") {
                  return false;
               }
               return true;
            })
            .map((i, key) => {
               if (i.submenu) {
                  return (
                     <SubMenu
                        key={`${prefix}_${key}`}
                        title={t(i.title)}
                        icon={
                           i.icon ? (
                              <FontAwesomeIcon fixedWidth transform="left-6" icon={i.icon} />
                           ) : (
                              <div
                                 style={{
                                    float: "left",
                                    width: 20,
                                    height: "100%",
                                 }}
                              ></div>
                           )
                        }
                     >
                        {showMenu(i.submenu, `${prefix}_${key}_`)}
                     </SubMenu>
                  );
               }
               if (i.title === "-") {
                  return <Menu.Divider key={`${prefix}_${key}`} />;
               } else {
                  return (
                     <Menu.Item
                        key={`${prefix}_${key}`}
                        icon={
                           i.icon ? (
                              <FontAwesomeIcon fixedWidth transform="left-6" icon={i.icon} />
                           ) : (
                              <div
                                 style={{
                                    float: "left",
                                    width: 20,
                                    height: "100%",
                                 }}
                              ></div>
                           )
                        }
                     >
                        <Link to={i.route}>{t(i.title)}</Link>
                     </Menu.Item>
                  );
               }
            })
      );
   };

   const getMenuByRole = (me) => {
      switch (me.role) {
         case USER.ROLES.ADMIN:
            return [showMenu(getFullMenu(me), 'menu_'), showMenu(adminMenu, "menu_admin_")];
         case USER.ROLES.MANUAL:
            return showMenu(fastMenu, 'menu_');
         case USER.ROLES.EMPLOYEE:
            return showMenu(employeeMenu, 'menu_');
         default:
            return showMenu(getFullMenu(me, 'menu_'));
      }
   }

   if (!me.module) {
      return null;
   }

   const menu = getMenuByRole(me);

   return (
      <>
         <Menu
            mode="inline"
            // inlineCollapsed={false}
            defaultSelectedKeys={["menu_1"]}
            style={{
               overflowY: "scroll",
               overflowX: "hidden",
               height: "100%",
            }}
         >
            {/* {modificare con permessi}
            {showMenu(me.role === USER.ROLES.MANUAL ? fast_menu : getFullMenu(me), "menu_")}
            {isAdmin(me) ? showMenu(admin_menu, "menu_admin_") : null} */}
            {menu}
         </Menu>
      </>
   );
};

export default MainMenu;
